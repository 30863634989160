import { Routes } from '@angular/router';
import { canActivate } from './auth/auth.guard';
import { EnumRoleUser } from './common/enums/enum-role';
import { P404Component } from './pages/error/404.component';
import { P500Component } from './pages/error/500.component';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./pages/container-layout/container-layout.routes').then(
                (m) => m.routes,
            ),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./modules/admin/admin.routes').then((m) => m.routes),
        data: {
            title: 'CMS',
            rootLink: '/admin',
            roles: [
                EnumRoleUser.SUPER_ADMIN,
                EnumRoleUser.ADMIN_SITE,
                EnumRoleUser.COMPANY_MANAGER,
            ],
        },
        canActivate: [canActivate],
    },
    {
        path: 'student',
        loadChildren: () =>
            import('./modules/student/student.routes').then((m) => m.routes),
        data: {
            title: 'Học & Thi',
            rootLink: '/student',
        },
        canActivate: [canActivate],
    },
    {
        path: 'courses',
        loadChildren: () =>
            import('./modules/trainee/courses/courses.routes').then(
                (m) => m.routes,
            ),
    },
    {
        path: 'setting',
        loadChildren: () =>
            import('./modules/setting/setting.routes').then((m) => m.routes),
        data: {
            title: 'Cài đặt web',
            rootLink: '/setting',

            roles: [
                EnumRoleUser.SUPER_ADMIN,
                EnumRoleUser.ADMIN_SITE,
                EnumRoleUser.COMPANY_MANAGER,
            ],
        },
        canActivate: [canActivate],
    },
    {
        path: 'login',
        loadComponent: () =>
            import('./pages/login/login.component').then(
                (m) => m.LoginComponent,
            ),
    },
    {
        path: 'signup',
        loadComponent: () =>
            import('./pages/signup/signup.component').then(
                (m) => m.SignupComponent,
            ),
    },

    {
        path: 'learning',
        loadComponent: () =>
            import('./pages/learning/learning.component').then(
                (m) => m.LearningComponent,
            ),
    },
    {
        path: '404',
        component: P404Component,
        data: {
            title: 'Page 404',
        },
    },
    {
        path: 'learning/exam',
        loadComponent: () =>
            import(
                './pages/learning/components/exersice-exam/exersice-exam.component'
            ).then((m) => m.ExersiceExamComponent),
    },
    {
        path: '500',
        component: P500Component,
        data: {
            title: 'Page 500',
        },
    },
    { path: '**', component: P404Component },
];
